import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';
import { FORM_FOCUS_STYLE } from '@/styles/form-style';

export const DivSelectContainer = styled.div<{ width?: `${number}px` | `${number}%` }>`
  display: grid;
  grid-gap: 8px;
  width: ${({ width }) => width ?? '100%'};

  ${FORM_FOCUS_STYLE};
`;

export const DivSelectPosition = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  > svg {
    position: absolute;
    top: 14px;
    right: 12px;
    pointer-events: none;
    cursor: pointer;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid ${COLOR.GRAY400};
  background-color: ${COLOR.WHITE};
  border-radius: 8px;
  outline: 0;
  font-size: ${FONT_SIZE.SIZE_16};
  color: ${COLOR.BLACK};
  cursor: pointer;
  /* セレクトボックスのデフォルトの下矢印を消すため */
  appearance: none;

  &:disabled {
    cursor: not-allowed;
    background: #f9f9f9;
    border-radius: 8px;
  }
`;

export const LabelText = styled.label`
  width: fit-content;
  font-weight: ${FONT_WEIGHT.BOLD};
`;
