import { useState, useEffect } from 'react';

import { isClient } from '@/consts/device';

export const useClient = () => {
  const [isBrowser, setIsBrowser] = useState(false);

  // MEMO : useEffect は Hydrate 後に行われる
  useEffect(() => {
    if (isClient) setIsBrowser(true);
  }, []);

  return { isBrowser };
};
