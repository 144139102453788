import { memo } from 'react';
import ReactSelectComponent from 'react-select';

import { ErrorMessage } from '@/components/forms/error-message';

import type { SelectOption } from '@/types/select-option';
import type { FocusEventHandler, ComponentProps } from 'react';

import { customStyles } from './custom-styles';
import * as Styled from './style';

export type ReactSelectProps = {
  options: SelectOption[];
  onOptionChange: (option: SelectOption) => void;
  placeholder?: string;
  labelText?: string;
  errorMessage?: string;
  defaultValue?: SelectOption | null;
  /** 配列かどうかの判定は`Array.isArray`を用いる */
  value?: SelectOption | SelectOption[];
  /** SelectComponent の`横幅`を指定する */
  width?: `${number}px` | `${number}%`;
  /** SelectComponentに`Focus`が当たった時に発火するイベントハンドラ */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /** SelectComponentから`Focus`が離れた時に発火するイベントハンドラ */
  onBlur?: FocusEventHandler<HTMLInputElement>;
} & ComponentProps<typeof ReactSelectComponent>;

/**
 * react-selectライブラリをカスタマイズした`Select Component`
 * @param props {@link ReactSelectProps}
 * @param ref {@link HTMLDivElement}
 */
const _ReactSelect = ({
  options,
  onOptionChange,
  labelText,
  placeholder = '',
  defaultValue,
  value,
  errorMessage,
  width,
  onFocus,
  onBlur,
  ...selectProps
}: ReactSelectProps) => {
  const isLabel = labelText !== undefined && labelText !== '';
  const isError = errorMessage !== undefined && errorMessage !== '';

  return (
    <Styled.DivSelectContainer
      width={width}
      isDisabled={selectProps.isDisabled}
      role='group'
      aria-label='セレクトボックス'
    >
      {isLabel && <Styled.LabelText>{labelText}</Styled.LabelText>}

      <ReactSelectComponent
        styles={customStyles}
        options={options}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(newValue) => onOptionChange(newValue as SelectOption)}
        {...selectProps}
      />

      {isError ? <ErrorMessage message={errorMessage} /> : null}
    </Styled.DivSelectContainer>
  );
};

/**
 * react-selectライブラリをカスタマイズした`Select Component`
 * @param props {@link ReactSelectProps}
 * @param ref {@link HTMLDivElement}
 */
export const ReactSelect = memo(_ReactSelect);
