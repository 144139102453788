import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';
import { LETTER_SPACE } from '@/styles/letter-space';
import { LINE_HEIGHT } from '@/styles/line-height';

import type { ButtonProps } from './type';

import { buttonStyleTypes } from './button-style-types';

export const Button = styled.button<Omit<ButtonProps, 'children' | 'type' | 'onBtnPressed'>>`
  display: block;
  text-align: center;
  cursor: pointer;
  /* ホバーをなめらかにするため */
  transition: opacity 0.3s;

  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  padding: ${({ padding }) => padding ?? '8px'};
  max-width: ${({ maxWidth }) => maxWidth ?? null};
  font-size: ${({ fontSize }) => (fontSize !== undefined ? FONT_SIZE[fontSize] : FONT_SIZE['SIZE_16'])};
  font-weight: ${({ fontWeight }) => (fontWeight !== undefined ? FONT_WEIGHT[fontWeight] : FONT_WEIGHT['BOLD'])};
  letter-spacing: ${({ letterSpace }) =>
    letterSpace !== undefined ? LETTER_SPACE[letterSpace] : LETTER_SPACE['NORMAL']};
  line-height: ${({ lineHeight }) => (lineHeight !== undefined ? LINE_HEIGHT[lineHeight] : LINE_HEIGHT['NORMAL'])};

  ${({ styleTypes, disabled }) => {
    switch (styleTypes) {
      case 'primary':
        return disabled
          ? css`
              ${buttonStyleTypes.PRIMARY_DISABLED}
            `
          : css`
              ${buttonStyleTypes.PRIMARY}
            `;
      case 'secondary':
        return disabled
          ? css`
              ${buttonStyleTypes.SECONDARY_DISABLED}
            `
          : css`
              ${buttonStyleTypes.SECONDARY}
            `;
      case 'tertiary':
        return disabled
          ? css`
              ${buttonStyleTypes.TERTIARY_DISABLED}
            `
          : css`
              ${buttonStyleTypes.TERTIARY}
            `;
      default:
        return;
    }
  }}
`;
