import styled from '@emotion/styled';

import { FONT_WEIGHT } from '@/styles/font-weight';
import { FORM_FOCUS_STYLE } from '@/styles/form-style';

import type { ReactSelectProps } from '.';

export const DivSelectContainer = styled.div<Pick<ReactSelectProps, 'width' | 'isDisabled'>>`
  display: grid;
  grid-gap: 8px;
  width: ${({ width }) => width ?? '100%'};

  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        & > div {
          background: #f9f9f9;
          border-radius: 8px;
        }
      `;
    }

    return ``;
  }}

  ${FORM_FOCUS_STYLE};
`;

export const LabelText = styled.label`
  width: fit-content;
  font-weight: ${FONT_WEIGHT.BOLD};
`;
