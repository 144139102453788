import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const ArrowDown = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M7.667 12.167a.914.914 0 0 0 .67-.289l5.748-5.624a.766.766 0 0 0 .248-.584c0-.469-.376-.837-.873-.837a.897.897 0 0 0-.626.253l-5.167 5.069-5.168-5.07a.93.93 0 0 0-.633-.252c-.49 0-.866.368-.866.837 0 .23.083.432.249.584l5.747 5.624a.943.943 0 0 0 .67.289Z'
      fill='#BABABA'
    />
  </svg>
);

export const IconArrowDown = memo(forwardRef(ArrowDown));
