import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';

export const DivSelectSkeleton = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid ${COLOR.GRAY400};
  background-color: ${COLOR.WHITE};
  border-radius: 8px;
`;
