import styled from '@emotion/styled';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* ホバーをなめらかにするため */
  transition: opacity 0.3s;

  @media (any-hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.45;
    // クリックイベント・タッチイベントを無効化する
    pointer-events: none;
  }
`;
