import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';

export const SpanMessage = styled.span`
  display: block;
  width: fit-content;
  color: ${COLOR.RED400};
  font-size: ${FONT_SIZE.SIZE_14};
  word-break: break-all;
`;
