import { useCallback } from 'react';
import { atom, useSetRecoilState } from 'recoil';

const STORE_KEY = 'auth-modal';

export const authModalAtoms = {
  /** AuthModalの開閉フラグ */
  isAuthModalOpen: atom({
    key: `${STORE_KEY}/isAuthModalOpen`,
    default: false,
  }),

  /** サインアップモーダルの表示状態 defaultで開いておきたいときにsetする */
  isSignUp: atom({
    key: `${STORE_KEY}/isSignUp`,
    default: false,
  }),

  /** ログインモーダルのOutsideClickを無効化するかどうか */
  isDisableOutsideClick: atom({
    key: `${STORE_KEY}/isDisableOutsideClick`,
    default: false,
  }),
};

type AuthModalOptions = {
  isDisableOutsideClick?: boolean;
};

export const useAuthModalAction = () => {
  const setIsAuthModalOpen = useSetRecoilState(authModalAtoms.isAuthModalOpen);
  const setIsDisableOutsideClick = useSetRecoilState(authModalAtoms.isDisableOutsideClick);

  const openAuthModal = useCallback((options?: AuthModalOptions) => {
    setIsAuthModalOpen(true);
    options?.isDisableOutsideClick && setIsDisableOutsideClick(true);
  }, []);

  const closeAuthModal = useCallback(() => {
    setIsAuthModalOpen(false);
    setIsDisableOutsideClick(false);
  }, []);

  return {
    openAuthModal,
    closeAuthModal,
  };
};
