import { memo } from 'react';

import { isTouchDisplay } from '@/consts/device';
import { useClient } from '@/hooks/use-client';

import type { ReactSelectProps } from './react-select';

import { ReactSelect } from './react-select';
import * as Styled from './style';
import { TouchDeviceSelect } from './touch-device-select';

const _Select = (props: ReactSelectProps) => {
  const { isBrowser } = useClient();
  if (!isBrowser) return <Styled.DivSelectSkeleton />;
  return isTouchDisplay ? <TouchDeviceSelect {...props} /> : <ReactSelect {...props} />;
};

export const Select = memo(_Select);
