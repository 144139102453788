import { memo, useEffect, useState } from 'react';

import { ErrorMessage } from '@/components/forms/error-message';
import { IconArrowDown } from '@/components/icons/arrow-down';

import type { ReactSelectProps } from '@/components/forms/select/react-select';
import type { ChangeEvent } from 'react';

import * as Styled from './style';

const _TouchDeviceSelect = ({
  options,
  onOptionChange,
  value,
  defaultValue,
  labelText,
  errorMessage,
  width,
  isDisabled,
}: ReactSelectProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue?.label);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const optionId = options.find((item) => item.label === value)?.value;
    onOptionChange({ label: value, value: optionId });
    setSelectedValue(value);
  };

  const isLabel = labelText !== undefined && labelText !== '';
  const isError = errorMessage !== undefined && errorMessage !== '';

  useEffect(() => {
    if (value === undefined) return;

    // valueが「配列」の場合の処理
    if (Array.isArray(value)) {
      if (value.length === 0) return;
      const selectedValue = options.find((item) => item.label === value[0].label)?.label ?? '';
      setSelectedValue(selectedValue);
      return;
    }

    // valueが配列でない場合の処理
    const selectedValue = options.find((item) => item.label === value.label)?.label ?? '';
    setSelectedValue(selectedValue);
  }, []);

  return (
    <Styled.DivSelectContainer width={width} role='group' aria-label='セレクトボックス'>
      {isLabel && <Styled.LabelText>{labelText}</Styled.LabelText>}

      <Styled.DivSelectPosition>
        <Styled.Select value={selectedValue} disabled={isDisabled ?? false} onChange={handleSelectChange}>
          {options.map((item) => (
            <option key={item.label} value={item.label}>
              {item.label}
            </option>
          ))}
        </Styled.Select>

        <IconArrowDown width={16} height={16} />
      </Styled.DivSelectPosition>

      {isError && <ErrorMessage message={errorMessage} />}
    </Styled.DivSelectContainer>
  );
};

export const TouchDeviceSelect = memo(_TouchDeviceSelect);
