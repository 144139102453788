import { memo } from 'react';

import * as Styled from './style';

type ErrorMessageProps = {
  /** エラーメッセージ */
  message: string;
};

const _ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Styled.SpanMessage aria-live='assertive'>{message}</Styled.SpanMessage>
);

/**
 * Form入力時にエラーがあった場合に表示する`ErrorMessage Component`
 * @param props {@link ErrorMessageProps}
 */
export const ErrorMessage = memo(_ErrorMessage);
