import { css } from '@emotion/react';

import { COLOR } from '@/styles/color';

export const buttonStyleTypes = {
  PRIMARY: css`
    background: ${COLOR.GREEN400};
    color: ${COLOR.WHITE};
    border-radius: 3px;

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  `,
  PRIMARY_DISABLED: css`
    background-color: ${COLOR.GRAY400};
    color: ${COLOR.BLACK};
    border-radius: 3px;
    opacity: 0.45;
    pointer-events: none;
  `,

  SECONDARY: css`
    background-color: ${COLOR.GRAY100};
    color: ${COLOR.BLACK};
    border-radius: 3px;

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  `,
  SECONDARY_DISABLED: css`
    background-color: ${COLOR.GRAY400};
    color: ${COLOR.BLACK};
    border-radius: 3px;
    opacity: 0.45;
    pointer-events: none;
  `,
  TERTIARY: css`
    background-color: ${COLOR.RED400};
    color: ${COLOR.WHITE};
    border-radius: 3px;

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  `,
  TERTIARY_DISABLED: css`
    background-color: ${COLOR.GRAY400};
    color: ${COLOR.BLACK};
    border-radius: 3px;
    opacity: 0.45;
    pointer-events: none;
  `,
};
