import { forwardRef, memo } from 'react';

import type { ButtonProps } from './type';
import type { Ref } from 'react';

import * as Styled from './style';

const _Button = (
  {
    children,
    type,
    styleTypes,
    onBtnPressed,
    width,
    height,
    padding,
    maxWidth,
    fontSize,
    fontWeight,
    letterSpace,
    lineHeight,
    disabled = false,
    ...buttonProps
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) => (
  <Styled.Button
    ref={ref}
    type={type}
    styleTypes={styleTypes}
    width={width}
    height={height}
    padding={padding}
    fontSize={fontSize}
    fontWeight={fontWeight}
    letterSpace={letterSpace}
    lineHeight={lineHeight}
    maxWidth={maxWidth}
    disabled={disabled}
    {...buttonProps}
    onClick={() => onBtnPressed()}
  >
    {children}
  </Styled.Button>
);

export const Button = memo(forwardRef(_Button));
