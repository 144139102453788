import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const ClearButton = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M11 20.167c5.015 0 9.167-4.161 9.167-9.167 0-5.015-4.161-9.167-9.176-9.167-5.006 0-9.158 4.152-9.158 9.167 0 5.006 4.161 9.167 9.167 9.167Zm-3.02-5.393a.736.736 0 0 1-.737-.745c0-.198.072-.387.216-.522l2.49-2.498-2.49-2.49A.72.72 0 0 1 7.243 8c0-.423.324-.738.737-.738.207 0 .369.072.513.207L11 9.966l2.525-2.507a.68.68 0 0 1 .512-.216c.414 0 .746.324.746.737a.695.695 0 0 1-.224.53L12.06 11.01l2.49 2.49a.687.687 0 0 1 .224.53.746.746 0 0 1-.754.745.711.711 0 0 1-.53-.215L11 12.06 8.52 14.56a.746.746 0 0 1-.54.216Z'
      fill='#BABABA'
    />
  </svg>
);

export const IconClearButton = memo(forwardRef(ClearButton));
