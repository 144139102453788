import { forwardRef, memo } from 'react';

import type { Ref } from 'react';

import * as Styled from './style';

type IconButtonProps = {
  /** アイコンボタンの名前（例 : `Delete Button`） */
  name: string;
  /** iconsディレクトリ配下の`SvgComponent` */
  svgComponent: JSX.Element;
  /** アイコンボタン押下時のイベントハンドラ */
  onBtnPressed: () => void;
  /** デフォルト値は`false` */
  isDisabled?: boolean;
} & JSX.IntrinsicElements['button'];

/**
 * SVGアイコンをボタンとして使用したい場合に使用するコンポーネント
 * @param props {@link IconButtonProps}
 * @param ref {@link HTMLButtonElement}
 */
const _IconButton = (
  { name, svgComponent, onBtnPressed, isDisabled = false, ...buttonProps }: IconButtonProps,
  ref: Ref<HTMLButtonElement>,
) => (
  <Styled.Button
    ref={ref}
    type='button'
    name={name}
    aria-label={name}
    disabled={isDisabled}
    onClick={(event) => {
      // 親要素にイベントが伝播するのを防ぐために、event.stopPropagation を指定する
      event.stopPropagation();
      onBtnPressed();
    }}
    {...buttonProps}
  >
    {svgComponent}
  </Styled.Button>
);

/**
 * SVGアイコンをボタンとして使用したい場合に使用するコンポーネント
 * @param props {@link IconButtonProps}
 * @param ref {@link HTMLButtonElement}
 */
export const IconButton = memo(forwardRef(_IconButton));
