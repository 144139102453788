import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';

export const customStyles = {
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    display: 'flex',
    background: COLOR.WHITE,
    cursor: 'pointer',
  }),
  placeholder: (defaultStyle: Record<string, unknown>) => ({
    ...defaultStyle,
    color: COLOR.GRAY400,
    fontSize: FONT_SIZE.SIZE_14,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (_provided: Record<string, unknown>, state: { isFocused: boolean }) => {
    const borderColor = state.isFocused ? COLOR.GREEN400 : COLOR.GRAY400;
    return {
      display: 'flex',
      boxShadow: 'none',
      border: `solid 1px ${borderColor}`,
      borderRadius: '8px',
      height: '40px',
      width: '100%',
      fontSize: FONT_SIZE.SIZE_14,
    };
  },
  option: (provided: Record<string, unknown>, state: { isSelected: boolean }) => {
    const fontWeight = state.isSelected ? FONT_WEIGHT.BOLD : FONT_WEIGHT.NORMAL;

    return {
      ...provided,
      fontWeight,
      fontSize: FONT_SIZE.SIZE_14,
      backgroundColor: COLOR.WHITE,
      color: COLOR.BLACK,
      '&:hover': {
        backgroundColor: COLOR.GRAY100,
        cursor: 'pointer',
      },
    };
  },
};
