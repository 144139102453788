import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const STORE_KEY = 'toast';

type ToastType = 'success' | 'error';

const toastAtoms = {
  /** Toastの開閉フラグ */
  isVisible: atom({
    key: `${STORE_KEY}/isVisible`,
    default: false,
  }),
  /** Toastに表示するテキスト */
  toastText: atom({
    key: `${STORE_KEY}/toastText`,
    default: '',
  }),
  /** Toastの表示タイプ */
  toastType: atom<ToastType>({
    key: `${STORE_KEY}/toastType`,
    default: 'success',
  }),
};

type OpenToastComponentArgs = {
  /** Toastに表示するテキスト */
  toastText: string;
  /** Toastの表示タイプ */
  toastType: ToastType;
};

export const useToast = () => {
  const isToastVisible = useRecoilValue(toastAtoms.isVisible);
  const toastText = useRecoilValue(toastAtoms.toastText);
  const toastType = useRecoilValue(toastAtoms.toastType);

  const setIsVisible = useSetRecoilState(toastAtoms.isVisible);
  const setToastText = useSetRecoilState(toastAtoms.toastText);
  const setToastType = useSetRecoilState(toastAtoms.toastType);

  const closeToast = useCallback(() => setIsVisible(false), []);

  const openToastComponent = useCallback((args: OpenToastComponentArgs) => {
    const { toastText, toastType } = args;

    setToastText(toastText);
    setToastType(toastType);
    setIsVisible(true);
  }, []);

  return {
    isToastVisible,
    toastText,
    toastType,
    openToastComponent,
    closeToast,
  };
};
